ul {
  list-style-type: none;
  padding: 0;
  margin:0;
}

.upload-image {
  width: '200px';
  height: '200px';
  border-width: '1px';
  border-radius: '10px';
  border-style: 'dashed';
  border-color: '#ddd';
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  border-bottom: solid #07F;
}

.drag-drop-zone {
  padding: 2rem;
  text-align: center;
  background: #07F;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 10px #C0C0C0;
}

.tree-selector .dropdown-trigger {
  border-radius: 0.25rem;
}

.tree-selector .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.tree-selector .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.tree-selector .toggle.collapsed::after {
  cursor: pointer;
  content: "[+]";
  vertical-align: middle;
}

.tree-selector .toggle.expanded::after {
  cursor: pointer;
  content: "[-]";
  vertical-align: middle;
}

.tree-selector .root {
  padding: 0px;
  margin: 0px;
}

.tag-badge {
  border-radius: 4px;
  padding: 7px;
  margin-right: 7px;
  margin-top: 7px;
  line-height: 14px;
  color: #323945;
}
